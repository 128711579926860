import React from "react";
import MenuAccountSettings from "../MenuAccountSettings";

const Header = ({ setMenuActive }) => {
  return (
    <div className="header-main-content">
      <div className="layout-width">
        <div className="navbar-header">
          <div
            className="navbar-header-item-main"
            onClick={() => setMenuActive(true)}
          >
            <span>Menu</span>
          </div>
          <div className="navbar-header-item-account-settings">
            <MenuAccountSettings />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
