import React from "react";
import ProductMarginItem from "./ProductMarginItem";
import { useDispatch } from "react-redux";
import { updateMarginGotovinskoValue } from "../../../actions/product";
import { Table } from "react-bootstrap";

const ProductMarginTable = ({ data }) => {
  const dispatch = useDispatch();

  const handleInputChange = (vrednost, kategorija, tip) => {
    const dataValue = {
      vrednost: vrednost,
      kategorija: kategorija,
      tip: tip,
    };

    dispatch(updateMarginGotovinskoValue(dataValue));
  };

  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>Grupa</th>
          <th>Marža procenat</th>
          <th>Gotovinsko procenat</th>
          <th>Status grupe</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, key) => (
          <ProductMarginItem
            key={key}
            data={item}
            onChange={handleInputChange}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default ProductMarginTable;
