import React from "react";
import breadcrumbsItems from "../../../utils/breadcrumbsItems";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { RefreshRounded } from "@mui/icons-material";

const Breadcrumb = (props) => {
  const { title, type } = props || {};

  const content = breadcrumbsItems(type);

  return (
    <div className="breadcrumb breadcrumb-main page-title-box d-sm-flex align-items-center justify-content-between">
      <div className="breadcrumb-title">{title}</div>
      <div className="breadcrumb-button">
        <Link to="/adminManager/products-check">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
          >
            Provera lagera proizvoda
          </Button>
        </Link>
      </div>
      <div className="breadcrumb-path">
        <div className="page-title-right">
          <ol className="breadcrumb m-0">{content}</ol>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
