import { contactFormActions } from "../reducers/Admin/contactFormActions";
import apiService from "../utils/configAxios";
import { toast } from "react-toastify";
import { getListContactFormPath } from "../constants/apiRoutes";

export const fetchContactFormPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(contactFormActions.actionStart());
    return apiService
      .get(getListContactFormPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(contactFormActions.fetchAllPagesList(response.data));
        else dispatch(contactFormActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
