import React, { useEffect } from "react";
import { Fragment } from "react";
import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import InputField from "../components/Admin/Reusable/InputField";
import { Autocomplete, Button, InputAdornment, TextField } from "@mui/material";
import { isEmpty } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { basicFormValidationHandler2 } from "../utils";
import {
  fetchCategoryGroupList,
  fetchCreateCategoryGroupMargin,
} from "../actions/product";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";

const ProductCategorySale = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { dataCategoryGroup } = useSelector((state) => state.productReducer);

  const resetState = useCallback(() => {
    return {
      acMargin: "",
      acGroup: "",
    };
  }, []);

  const initialState = {
    acMargin: "",
    acGroup: "",
  };

  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(fetchCategoryGroupList());
  }, [id, dispatch, resetState]);

  const productValidationHandler = () => {
    const { acMargin, acGroup } = data;

    let errorsObject = {};
    errorsObject = basicFormValidationHandler2({
      acMargin,
      acGroup,
    });

    setErrors(errorsObject);
    if (!isEmpty(errorsObject))
      toast.error("Morate popuniti sva obavezna polja", { theme: "colored" });
    return !isEmpty(errorsObject);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (productValidationHandler()) {
      return;
    }

    const formData = new FormData();

    formData.append("acMargin", data.acMargin);
    formData.append("acGroup", data.acGroup);

    // alert("KREIRAJ!");
    dispatch(fetchCreateCategoryGroupMargin(formData));
  };

  const handleChange = (event, newValue) => {
    setData((prevUser) => ({
      ...prevUser,
      ["acGroup"]: newValue.naziv_kategorije,
    }));
  };

  return (
    <Fragment>
      <Breadcrumb title="Grupa proizvoda" type="product-forma" />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h1 className="card-title mb-0 flex-grow-1">
                {/* Kreiranje novog pitanja */}
                {id
                  ? `Kreiranje novih cena proizvoda po grupi`
                  : "Kreiranje novih cena proizvoda po grupi"}
              </h1>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit} noValidate>
                <div className="row">
                  <div className="col-6">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={dataCategoryGroup}
                      name="acGroup"
                      onChange={handleChange}
                      getOptionLabel={(option) => `${option.naziv_kategorije}`}
                      renderInput={(params) => (
                        <TextField {...params} label="Grupa proizvoda" />
                      )}
                    />
                    <InputField
                      required
                      label="Procenat umanjenja ili povecanja cena (- za oduzimanje)"
                      name="acMargin"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acMargin}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4 mt-4"
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className="float-end mt-3">
                  {id ? (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<CheckIcon />}
                    >
                      Potvrda izmene
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<AddIcon />}
                    >
                      Kreiraj
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductCategorySale;
