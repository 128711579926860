import React, { Fragment, useCallback, useEffect, useState } from "react";
import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Input,
  FormHelperText,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { toast } from "react-toastify";
import {
  createNewDocument,
  fetchDocumentPagesList,
  getFileReadFromDocument,
  editDocumentFunction,
  fetchAllDocumentCategories,
  deleteDocumentCategoryItem,
} from "../actions/document";
import { basicFormValidationHandler } from "../utils";
import { isEmpty, isUndefined } from "lodash";
import InputField from "../components/Admin/Reusable/InputField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomModal from "../components/Common/Modal/CustomModal";
import { useModalToggle } from "../hooks/useModalToggle";
import EditIcon from "@mui/icons-material/Edit";
import AddNewDocumentCategory from "./Document/AddNewDocumentCategory";

const Document = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dataPagesDetail, dataDocumentCategoriesList } = useSelector(
    (state) => state.documentReducer
  );

  const resetState = useCallback(() => {
    return {
      anStatus: "1",
      anCategory: 0,
      anLanguage: "1",
      acName: "",
    };
  }, []);

  const initialState = {
    anStatus: "1",
    anCategory: 0,
    anLanguage: "1",
    acName: "",
  };

  const [data, setData] = useState(initialState);
  const [deletedItem, setDeletedItem] = useState({
    anId: 0,
    acCategoryName: "",
  });
  const [showModal, toggleModal] = useModalToggle(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState(null);

  const changedItemCategory = dataDocumentCategoriesList.filter(
    (item) => item.anId === selectedCategoryItem
  );

  const dataDocumentCategoriesListByLang = dataDocumentCategoriesList.filter(
    (item) => item.anLanguage === data.anLanguage
  );

  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState("");
  // Funkcija za rukovanje izborom datoteke
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSizeInMB = file.size / 1048576; // Konvertujemo veličinu u MB
      if (fileSizeInMB <= 5) {
        setSelectedFile(file);
      } else {
        toast.error(
          "Odabrani dokument je prevelik. Maksimalna veličina je 5MB.",
          { theme: "colored" }
        );
        setData((prevUser) => ({
          ...prevUser,
          acDocument: null,
        }));
        event.target.value = null; // Resetujemo input
      }
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchDocumentPagesList(id));
    } else {
      setData(resetState());
    }
    dispatch(fetchAllDocumentCategories());
  }, [id, dispatch, resetState]);

  useEffect(() => {
    if (dataPagesDetail && Object.keys(dataPagesDetail).length > 0) {
      setData(dataPagesDetail);
    }
  }, [dataPagesDetail]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "anLanguage") {
      setData((prevUser) => ({
        ...prevUser,
        [name]: value,
        anCategory: 0,
      }));
    } else {
      setData((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    }
  };

  const clientValidationHandler = () => {
    const { acName, anCategory } = data;
    const errorsObject = basicFormValidationHandler({
      acName,
      anCategory,
    });
    setErrors(errorsObject);
    if (!isEmpty(errorsObject))
      toast.error("Morate popuniti sva obavezna polja", { theme: "colored" });
    return !isEmpty(errorsObject);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (clientValidationHandler()) {
      return;
    }

    const formData = new FormData();

    formData.append("acName", data.acName);
    formData.append("anCategory", data.anCategory);
    formData.append("anStatus", data.anStatus);
    formData.append("anLanguage", data.anLanguage);
    selectedFile && formData.append("acDocument", selectedFile);

    if (id) {
      formData.append("anId", id);
      dispatch(editDocumentFunction(formData, navigate));
    } else {
      dispatch(createNewDocument(formData, navigate));
    }
  };

  const handleFileRead = (document) => {
    dispatch(getFileReadFromDocument(document));
  };

  const modalCloseHandler = () => {
    toggleModal(false);
  };

  const modalCloseHandlerCategory = () => {
    setCategoryModal(false);
  };

  const handlerShowCategoryItems = () => {
    dispatch(fetchAllDocumentCategories());
    toggleModal();
  };

  const handlerEditCategory = (item) => {
    setCategoryModal(true);
    setSelectedCategoryItem(item.anId);
  };

  const handlerCategoryModal = () => {
    setCategoryModal(true);
    setSelectedCategoryItem([]);
  };

  const handlerDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleSubmitDeleteDocumentCategory = () => {
    if (deletedItem.anId > 0) {
      dispatch(
        deleteDocumentCategoryItem(deletedItem.anId, handlerDeleteModal)
      );

      setData((prevUser) => ({
        ...prevUser,
        anCategory: 0,
      }));
    }
  };

  const setShowDeleteModalFunction = (item) => {
    setDeletedItem({
      anId: item.anId,
      acCategoryName: item.acCategoryName,
    });
    setShowDeleteModal(true);
  };

  return (
    <Fragment>
      <Breadcrumb title="Dokumenta" type="document-forma" />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h1 className="card-title mb-0 flex-grow-1">
                {/* Kreiranje novog pitanja */}
                {id
                  ? `Izmena dokumenta ${data.acName}`
                  : "Kreiranje novog dokumenta"}
              </h1>
            </div>
            <div className="card-body">
              <form
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                noValidate
              >
                <div className="row">
                  <div className="col-6">
                    {id && (
                      <div className="inseted-document-link">
                        Dokument:&nbsp;
                        <div
                          onClick={() =>
                            handleFileRead(dataPagesDetail.acDocument)
                          }
                        >
                          {dataPagesDetail.acOrigianlName}
                        </div>
                      </div>
                    )}
                    <div className="info-box-required-inputs">
                      <small>
                        <i>polja označena * su obavezna</i>
                      </small>
                    </div>
                    <FormControl fullWidth className="mb-4">
                      <InputLabel id="demo-simple-select-label">
                        Jezik
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.anLanguage}
                        label="Jezik"
                        name="anLanguage"
                        onChange={handleInputChange}
                        size="small"
                      >
                        <MenuItem value="1">Srpski</MenuItem>
                        <MenuItem value="2">Engleski</MenuItem>
                      </Select>
                    </FormControl>
                    <div className="section-input-with-button">
                      <div className="row">
                        <div className="col-7">
                          <FormControl
                            fullWidth
                            className="mb-4"
                            error={errors.anCategory ? true : false}
                            required
                          >
                            <InputLabel id="demo-simple-select-label">
                              Kategorija
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={data.anCategory}
                              label="Kategorija"
                              name="anCategory"
                              onChange={handleInputChange}
                              size="small"
                            >
                              <MenuItem value={0}>
                                Izaberite kategoriju...
                              </MenuItem>
                              {dataDocumentCategoriesListByLang?.map(
                                (item, key) => (
                                  <MenuItem value={item.anId} key={key}>
                                    {item.acCategoryName}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                            {errors.anCategory && (
                              <FormHelperText>Obavezno polje!</FormHelperText>
                            )}
                          </FormControl>
                        </div>
                        <div className="col-5">
                          <Button
                            variant="contained"
                            color="warning"
                            type="button"
                            startIcon={<EditIcon />}
                            fullWidth
                            onClick={handlerShowCategoryItems}
                          >
                            Izmena kategorija
                          </Button>
                        </div>
                      </div>
                    </div>
                    <InputField
                      label="Naziv dokumenta"
                      name="acName"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acName}
                      onChange={handleInputChange}
                      required
                      fullWidth
                      size="small"
                      className="mb-4"
                    />
                    <FormControl fullWidth className="mb-4">
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.anStatus}
                        label="Status"
                        name="anStatus"
                        onChange={handleInputChange}
                        size="small"
                      >
                        <MenuItem value="1">Aktivan</MenuItem>
                        <MenuItem value="0">Neaktivan</MenuItem>
                      </Select>
                    </FormControl>
                    {id && (
                      <Fragment>
                        <div className="row">
                          <div className="col-6">
                            <TextField
                              label="Datum kreiranja dokumenta"
                              disabled
                              InputLabelProps={{
                                shrink: true, // Postavlja labelu iznad čak i kada je onemogućeno polje
                              }}
                              InputProps={{
                                style: {
                                  opacity: 1, // Podesite providnost da bi polje bilo vidljivo
                                },
                              }}
                              value={dataPagesDetail.adTimeIns}
                              fullWidth
                              size="small"
                              className="mb-4"
                            />
                            <TextField
                              label="Datum poslednje izmene dokumenta"
                              disabled
                              InputLabelProps={{
                                shrink: true, // Postavlja labelu iznad čak i kada je onemogućeno polje
                              }}
                              InputProps={{
                                style: {
                                  opacity: 1, // Podesite providnost da bi polje bilo vidljivo
                                },
                              }}
                              value={dataPagesDetail.adTimeEdit}
                              fullWidth
                              size="small"
                              className="mb-4"
                            />
                          </div>
                          <div className="col-6">
                            <TextField
                              label="Kreirao korisnik"
                              disabled
                              InputLabelProps={{
                                shrink: true, // Postavlja labelu iznad čak i kada je onemogućeno polje
                              }}
                              InputProps={{
                                style: {
                                  opacity: 1, // Podesite providnost da bi polje bilo vidljivo
                                },
                              }}
                              value={dataPagesDetail.ins_acUsername}
                              fullWidth
                              size="small"
                              className="mb-4"
                            />
                            <TextField
                              label="Izmenio korisnik"
                              disabled
                              InputLabelProps={{
                                shrink: true, // Postavlja labelu iznad čak i kada je onemogućeno polje
                              }}
                              InputProps={{
                                style: {
                                  opacity: 1, // Podesite providnost da bi polje bilo vidljivo
                                },
                              }}
                              value={dataPagesDetail.edit_acUsername}
                              fullWidth
                              size="small"
                              className="mb-4"
                            />
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                  <div className="col-6">
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ marginBottom: "10px" }}
                    >
                      Dokument <br />
                      <small>
                        <i>
                          (maksimalna veličina dokumenta do <b>5MB</b>)
                        </i>
                      </small>
                      <br />
                      <small>
                        <i>
                          (dozvoljeni formati <b>.pdf, .doc, .docx</b>)
                        </i>
                      </small>
                    </InputLabel>
                    <Input
                      required
                      type="file"
                      error={!isUndefined(errors["selectedFile"])}
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.files.length === 0) {
                          setSelectedFile(null);
                        } else {
                          handleFileSelect(e);
                        }
                      }}
                      id="file-input"
                      name="acFileName"
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="file-input"
                      style={{ position: "relative" }}
                    >
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                      >
                        Priložite dokument
                      </Button>
                      {!isUndefined(errors["selectedFile"]) && (
                        <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                          prilog je obavezan!
                        </p>
                      )}
                    </label>
                    {selectedFile && (
                      <div>
                        <br />
                        <table className="table">
                          <tbody>
                            <tr>
                              <td>
                                Odabrana datoteka: <b>{selectedFile.name} </b>{" "}
                                (Veličina:{" "}
                                {(selectedFile.size / 1048576).toFixed(2)} MB)
                              </td>
                              <td>
                                <Button
                                  variant="contained"
                                  color="error"
                                  type="button"
                                  size="small"
                                  startIcon={<DeleteIcon />}
                                  onClick={() => {
                                    setSelectedFile(null);
                                    document.getElementById(
                                      "file-input"
                                    ).value = ""; // Resetovanje input polja za datoteku
                                  }}
                                >
                                  Obriši
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p></p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="float-end mt-3">
                  {id ? (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<CheckIcon />}
                    >
                      Potvrda izmene
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<AddIcon />}
                    >
                      Kreiraj
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <CustomModal
          size={"lg"}
          show={showModal}
          hideModal={modalCloseHandler}
          modalTitle={`Izmena kategorija`}
          classValue={`modal-1`}
        >
          <div className="line-buttons-action-popup">
            <Button
              variant="contained"
              color="info"
              startIcon={<AddIcon />}
              size="small"
              onClick={() => handlerCategoryModal(true)}
            >
              Dodaj novu kategoriju
            </Button>
          </div>
          <div className="body-content-holder">
            <table className="table">
              <thead>
                <tr>
                  <th>Naziv kategorije</th>
                  <th>Jezik</th>
                  <th>Akcija</th>
                </tr>
              </thead>
              <tbody>
                {dataDocumentCategoriesList?.map((item, key) => (
                  <tr key={key}>
                    <td>{item.acCategoryName}</td>
                    <td>{item.acLanguageName}</td>
                    <td>
                      <Button
                        variant="outlined"
                        color="success"
                        startIcon={<EditIcon />}
                        size="small"
                        style={{ marginRight: "10px" }}
                        onClick={() => handlerEditCategory(item)}
                      >
                        Izmeni
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteIcon />}
                        size="small"
                        onClick={() => setShowDeleteModalFunction(item)}
                      >
                        Obriši
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CustomModal>
      )}

      {showDeleteModal && (
        <CustomModal
          show={showDeleteModal}
          hideModal={() => setShowDeleteModal(false)}
          modalTitle={`Potvrda brisanja`}
          actionSubmitModal={() => handleSubmitDeleteDocumentCategory()}
        >
          Da li ste sigurni da želite da obrišete{" "}
          <b>{deletedItem.acCategoryName}</b> ?
        </CustomModal>
      )}

      {categoryModal && (
        <AddNewDocumentCategory
          modalCloseHandlerCategory={modalCloseHandlerCategory}
          showModal={showModal}
          dispatch={dispatch}
          navigate={navigate}
          changedItemCategory={changedItemCategory}
          dataDocumentCategoriesList={dataDocumentCategoriesList}
        />
      )}
    </Fragment>
  );
};

export default Document;
