import {
  createNewTestimonialsPath,
  editTestimonialsPath,
  getListTestimonialsPath,
  removeTestimonialsPath,
} from "../constants/apiRoutes";
import { testimonialsActions } from "../reducers/Admin/testimonialsActions";
import apiService from "../utils/configAxios";
import { toast } from "react-toastify";

export const fetchCreateNewTestimonials = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(createNewTestimonialsPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/testimonials-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchDeleteTestimonials = (pageId) => {
  return (dispatch) => {
    dispatch(testimonialsActions.actionStart());
    return apiService
      .delete(removeTestimonialsPath(pageId))
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        return apiService
          .get(getListTestimonialsPath(0))
          .then((response) => {
            dispatch(testimonialsActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchEditTestimonials = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(editTestimonialsPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/testimonials-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchTestimonialsPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(testimonialsActions.actionStart());
    return apiService
      .get(getListTestimonialsPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(testimonialsActions.fetchAllPagesList(response.data));
        else dispatch(testimonialsActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
