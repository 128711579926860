import React from "react";
import { Fragment } from "react";
import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";
import AggridTable from "../components/Admin/Reusable/AggridTable";
import ButtonColumn from "../components/Admin/Reusable/ButtonColumn";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchDeleteProduct, fetchSyncList } from "../actions/product";

import LoadingIndicator from "../components/Admin/Reusable/LoadingIndicator";

const ProductSync = () => {
  const dispatch = useDispatch();

  const { loading, dataSyncList } = useSelector(
    (state) => state.productReducer
  );

  useEffect(() => {
    dispatch(fetchSyncList());
  }, [dispatch]);

  //akcija brisanja iz liste
  const handleSubmitModal = (pageId) => {
    dispatch(fetchDeleteProduct(pageId));
  };

  console.log(dataSyncList, "dataSyncList");

  const imageRenderer = (params) => (
    <img
      src={params.data.slika}
      className="img-fluid image-preview-block-table"
      alt="UP IT"
    />
  );

  const customCellRenderer = (params) => (
    <ButtonColumn
      editLink={`/adminManager/product-edit/` + params.data.anId}
      deleteItemId={params.data.anId}
      deleteItemTitle={params.data.acName}
      value={params}
      submitActionModal={handleSubmitModal}
    />
  );

  //settings za tabelu
  const columnDefs = [
    { headerName: "ID", field: "id", filter: true, width: 100 },
    {
      headerName: "Datum pocetka azuriranja",
      field: "datum_sync_pocetak",
      filter: "agTextColumnFilter",
      flex: 1,
    },
    {
      headerName: "Datum zavrsetka azuriranja",
      field: "datum_sync_kraj",
      filter: "agTextColumnFilter",
      flex: 1,
    },
    {
      headerName: "Broj proizvoda",
      field: "insertovanih",
      filter: "agTextColumnFilter",
      flex: 1,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb title="Pregled azuriranja podataka" type="product-sync" />
      <AggridTable
        rowData={dataSyncList}
        columnDefs={columnDefs}
        paginationPageSize={150}
      />
      <LoadingIndicator loading={loading} />
    </Fragment>
  );
};

export default ProductSync;
