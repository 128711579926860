import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { locationPath } from "../constants/appRoutes";
import ArticleIcon from "@mui/icons-material/Article";
import ContactsIcon from "@mui/icons-material/Contacts";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import FeedIcon from "@mui/icons-material/Feed";

const Dashboard = () => {
  const loggedUserReducer = useSelector((state) => state.loggedUserReducer);

  return (
    <div className="dashboard-content-holder">
      <div className="row">
        <div className="col">
          <div className="row mb-3 pb-1">
            <div className="col-12">
              <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                <div className="flex-grow-1">
                  <h4 className="fs-16 mb-1">
                    Dobrodošli nazad,{" "}
                    {loggedUserReducer?.loggedUser?.loggedUser?.first_name}{" "}
                    {loggedUserReducer?.loggedUser?.loggedUser?.last_name}!
                  </h4>
                  <p className="text-muted mb-0">
                    Radujemo se što vas vidimo ovde.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-6">
              <div className="card card-animate">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                        {" "}
                        Ukupno proizvoda
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <h5 className="text-success fs-14 mb-0">
                        <i className="ri-arrow-right-up-line fs-13 align-middle"></i>{" "}
                      </h5>
                    </div>
                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h4 className="fs-22 fw-semibold ff-secondary mb-3">
                        <span className="counter-value" data-target="559.25">
                          4160
                        </span>
                      </h4>
                      <Link
                        to={locationPath.PRODUCT_LIST}
                        className="text-decoration-underline"
                      >
                        Pogledaj sve proizvode
                      </Link>
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-primary-subtle rounded fs-3">
                        <ArticleIcon />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="card card-animate">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                        Ukupno aktivnih proizvoda
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <h5 className="text-danger fs-14 mb-0">
                        <i className="ri-arrow-right-down-line fs-13 align-middle"></i>{" "}
                      </h5>
                    </div>
                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h4 className="fs-22 fw-semibold ff-secondary mb-3">
                        <span className="counter-value" data-target="36894">
                          4159
                        </span>
                      </h4>
                      <Link
                        to={locationPath.PRODUCT_LIST}
                        className="text-decoration-underline"
                      >
                        Pogledaj sve proizvode
                      </Link>
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-info-subtle rounded fs-3">
                        <ContactsIcon />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="card card-animate">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                        Ukupno neaktivnih proizvoda
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <h5 className="text-success fs-14 mb-0">
                        <i className="ri-arrow-right-up-line fs-13 align-middle"></i>{" "}
                      </h5>
                    </div>
                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h4 className="fs-22 fw-semibold ff-secondary mb-3">
                        <span className="counter-value" data-target="183.35">
                          2
                        </span>
                      </h4>
                      <Link
                        to={locationPath.PRODUCT_LIST}
                        className="text-decoration-underline"
                      >
                        Pogledaj sve proizvode
                      </Link>
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-primary-subtle rounded fs-3">
                        <QuestionAnswerIcon />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="card card-animate">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                        {" "}
                        Poslednje vreme ažuriranja proizvoda
                      </p>
                    </div>
                    <div className="flex-shrink-0"></div>
                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h4 className="fs-22 fw-semibold ff-secondary mb-3">
                        <span className="counter-value" data-target="15">
                          2024-02-22 23:26:28
                        </span>
                      </h4>
                      <Link
                        to={locationPath.PRODUCT_LIST}
                        className="text-decoration-underline"
                      >
                        Pogledaj sve proizvode
                      </Link>
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-info-subtle rounded fs-3">
                        <FeedIcon />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col col-left-dashboard"></div>
      </div>
    </div>
  );
};

export default Dashboard;
