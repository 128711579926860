import {
  createNewRolePath,
  editRolePath,
  getListRolePath,
  removeRolePath,
} from "../constants/apiRoutes";
import { roleActions } from "../reducers/Admin/roleActions";
import apiService from "../utils/configAxios";
import { toast } from "react-toastify";

export const fetchCreateNewRole = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(createNewRolePath(), dataValues)
      .then((response) => {
        navigate("/adminManager/role-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchDeleteRole = (pageId) => {
  return (dispatch) => {
    dispatch(roleActions.actionStart());
    return apiService
      .delete(removeRolePath(pageId))
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        return apiService
          .get(getListRolePath(0))
          .then((response) => {
            dispatch(roleActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchEditRole = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(editRolePath(), dataValues)
      .then((response) => {
        navigate("/adminManager/role-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchRolePagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(roleActions.actionStart());
    return apiService
      .get(getListRolePath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(roleActions.fetchAllPagesList(response.data));
        else dispatch(roleActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
