import React, { useState } from "react";
import { Fragment } from "react";

import LeftSidebar from "../../../components/Admin/Layout/LeftSidebar";
import Header from "../../../components/Admin/Layout/Header";
import Footer from "../../../components/Admin/Layout/Footer";

const ThemeWrapperAdmin = (props) => {
  const [menuActive, setMenuActive] = useState(false);

  const handleOutsideClick = () => {
    console.log(123);
    setMenuActive(false);
  };

  return (
    <div>
      <LeftSidebar menuActive={menuActive} onClick={handleOutsideClick} />
      <Header setMenuActive={setMenuActive} />
      <div className="main-content-conteiner">
        <div className="container-fluid">
          <div className="page-content">{props.children}</div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ThemeWrapperAdmin;
