import {
  createNewBlogPath,
  createNewDetailBlogPath,
  editBlogPath,
  getListBlogPath,
  getListBlogDetailPath,
  removeBlogPath,
  removeBlogTranslationPath,
  editBlogTranslationPath,
} from "../constants/apiRoutes";
import { blogActions } from "../reducers/Admin/blogActions";
import apiService from "../utils/configAxios";
import { toast } from "react-toastify";

export const fetchCreateNewBlog = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(createNewBlogPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/blog-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchCreateNewDetailBlog = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(createNewDetailBlogPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/blog-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchDeleteBlog = (pageId) => {
  return (dispatch) => {
    dispatch(blogActions.actionStart());
    return apiService
      .delete(removeBlogPath(pageId))
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        return apiService
          .get(getListBlogPath(0))
          .then((response) => {
            dispatch(blogActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchDeleteBlogTranslation = (pageId, navigate) => {
  return (dispatch) => {
    dispatch(blogActions.actionStart());
    return apiService
      .delete(removeBlogTranslationPath(pageId))
      .then((response) => {
        navigate("/adminManager/blog-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchEditBlog = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(editBlogPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/blog-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchEditTranslationBlog = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(editBlogTranslationPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/blog-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchBlogPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(blogActions.actionStart());
    return apiService
      .get(getListBlogPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(blogActions.fetchAllPagesList(response.data));
        else dispatch(blogActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchBlogDetailPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(blogActions.actionStart());
    return apiService
      .get(getListBlogDetailPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(blogActions.fetchAllPagesTranslationList(response.data));
        else
          dispatch(blogActions.fetchAllPagesDetailTranslation(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
