import {
  addNewQa,
  getQaPagesList,
  editQa,
  qaItemDelete,
  changeOrderQaItems,
  getQaPagesOrderList,
} from "../constants/apiRoutes";
import { qaActions } from "../reducers/Admin/qaActions";
import apiService from "../utils/configAxios";
import { toast } from "react-toastify";

export const createNewQa = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(addNewQa(), dataValues)
      .then((response) => {
        navigate("/adminManager/qa-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        const result = err.response.data.messages;
        for (const field in result) {
          const message = result[field];
          toast.error(message, { theme: "colored" });
        }
      });
  };
};

export const fetchChangeOrderItems = (dataValues) => {
  return (dispatch) => {
    return apiService
      .post(changeOrderQaItems(), dataValues)
      .then((response) => {
        toast.success("Uspešno ste zamenili mesta", { theme: "colored" });
      })
      .catch((err) => {
        const result = err.response.data.messages;
        for (const field in result) {
          const message = result[field];
          toast.error(message, { theme: "colored" });
        }
      });
  };
};

export const editQaForm = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(editQa(), dataValues)
      .then((response) => {
        navigate("/adminManager/qa-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        const result = err.response.data.messages;
        for (const field in result) {
          const message = result[field];
          toast.error(message, { theme: "colored" });
        }
      });
  };
};

export const fetchQaPagesList = (categoryId = 0) => {
  return (dispatch) => {
    dispatch(qaActions.actionStart());
    return apiService
      .get(getQaPagesList(categoryId))
      .then((response) => {
        dispatch(qaActions.fetchAllPagesList(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchQaDetailData = (categoryId = 0) => {
  return (dispatch) => {
    dispatch(qaActions.actionStart());
    return apiService
      .get(getQaPagesList(categoryId))
      .then((response) => {
        dispatch(qaActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchQaPagesOrderList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(qaActions.actionStart());
    return apiService
      .get(getQaPagesOrderList(pageId))
      .then((response) => {
        dispatch(qaActions.fetchAllPagesList(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const deleteItem = (pageId) => {
  return (dispatch) => {
    dispatch(qaActions.actionStart());
    return apiService
      .delete(qaItemDelete(pageId))
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        return apiService
          .get(getQaPagesList(0))
          .then((response) => {
            dispatch(qaActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
