import React from "react";
import { Fragment } from "react";
import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";
import AggridTable from "../components/Admin/Reusable/AggridTable";
import ButtonColumn from "../components/Admin/Reusable/ButtonColumn";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchUserPagesList, fetchDeleteUser } from "../actions/user";
import { statusRender } from "../utils/cellRenderTable";

const UserList = () => {
  const dispatch = useDispatch();

  const { dataPagesList } = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(fetchUserPagesList());
  }, [dispatch]);

  //akcija brisanja iz liste
  const handleSubmitModal = (pageId) => {
    dispatch(fetchDeleteUser(pageId));
  };

  const customCellRenderer = (params) => (
    <ButtonColumn
      editLink={`/adminManager/user-edit/` + params.data.anId}
      deleteItemId={params.data.anId}
      deleteItemTitle={params.data.acName}
      value={params}
      submitActionModal={handleSubmitModal}
    />
  );

  //settings za tabelu
  const columnDefs = [
    { headerName: "ID", field: "anId", filter: true, width: 100 },
    {
      headerName: "Username",
      field: "acUsername",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Ime",
      field: "acFirstName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Prezime",
      field: "acLastName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Email",
      field: "acEmail",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Rola",
      field: "acRoleName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Status",
      field: "acStatusName",
      filter: "agTextColumnFilter",
      cellRenderer: statusRender,
    },
    {
      headerName: "Telefon",
      field: "acPhone",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Kreirao",
      field: "ins_acUsername",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Izmenio",
      field: "edit_acUsername",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Datum kreiranja",
      field: "adTimeIns",
      filter: "agDateColumnFilter",
    },
    {
      headerName: "Datum izmene",
      field: "adTimeEdit",
      filter: "agDateColumnFilter",
    },
    {
      headerName: "Panel",
      field: "custom",
      cellRenderer: customCellRenderer,
      pinned: "right",
      width: 120,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb title="Pregled klijenata" type="user-lista" />
      <AggridTable rowData={dataPagesList} columnDefs={columnDefs} />
    </Fragment>
  );
};

export default UserList;
