import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { fetchSeoPagesList, updateSeoItem } from "../actions/seo";
import { Autocomplete, Button, Chip, TextField } from "@mui/material";
import { formatDate } from "../utils/date";

const SeoPagesForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { seoPagesList, loading } = useSelector((state) => state.seoReducer);
  const [data, setData] = useState({
    acName: "",
    acTitle: "",
    acKeywords: "",
    acDescription: "",
    ins_acUsername: "",
    edit_acUsername: "",
    adTimeEdit: "",
    adTimeIns: "",
    anId: "",
    anPageId: "",
    anStatus: "",
    anUserEdit: "",
    anUserIns: "",
  });
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    dispatch(fetchSeoPagesList(id));
  }, [dispatch, id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const tagsString = tags.join(",");
    const updatedData = {
      ...data,
      acKeywords: tagsString,
    };
    dispatch(updateSeoItem(updatedData, navigation));
  };

  useEffect(() => {
    if (seoPagesList && Object.keys(seoPagesList).length > 0) {
      setData(seoPagesList[0]);
    }
  }, [seoPagesList]);

  useEffect(() => {
    // Prethodno razdvoji string tagova u niz tagova
    const tagArray = data.acKeywords?.split(",").map((tag) => tag.trim());
    setTags(tagArray);
  }, [data.acKeywords]);

  const handleRemoveTag = (tag) => {
    const newTags = tags.filter((t) => t !== tag);
    setTags(newTags);
  };

  return (
    <div>
      <Breadcrumb title={`Izmena seo stranice`} type="seo-form-edit" />
      {!loading && (
        <Fragment>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Dodavanje novog korisnika
                  </h4>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-6 mb-4">
                        <TextField
                          label="Naziv stranice"
                          name="acName"
                          value={data.acName}
                          onChange={handleInputChange}
                          required
                          fullWidth
                          size="small"
                          className="mb-4"
                          disabled
                        />
                        <TextField
                          label="Naslov"
                          name="acTitle"
                          value={data.acTitle}
                          onChange={handleInputChange}
                          required
                          fullWidth
                          size="small"
                          className="mb-4"
                        />
                        <Autocomplete
                          className="mb-4"
                          clearIcon={false}
                          options={[]}
                          freeSolo
                          multiple
                          value={tags}
                          inputValue={inputValue}
                          onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                          }}
                          onChange={(event, newTags) => {
                            setTags(newTags);
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                key={index}
                                label={option}
                                {...getTagProps({ index })}
                                onDelete={() => handleRemoveTag(option)}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField label="Keyowrds" {...params} />
                          )}
                        />
                        <TextField
                          id="outlined-multiline-static"
                          label="Opis"
                          multiline
                          rows={4}
                          name="acDescription"
                          value={data.acDescription}
                          onChange={handleInputChange}
                          fullWidth
                          variant="outlined"
                          className="mb-4"
                        />
                      </div>
                      <div className="col-6">
                        <TextField
                          label="Datum kreiranja"
                          type="date"
                          name="adTimeIns"
                          value={formatDate(data.adTimeIns)}
                          onChange={handleInputChange}
                          fullWidth
                          size="small"
                          className="mb-4"
                          disabled
                        />
                        <TextField
                          label="Kreirao korisnik"
                          name="ins_acUsername"
                          value={data.ins_acUsername}
                          onChange={handleInputChange}
                          fullWidth
                          size="small"
                          className="mb-4"
                          disabled
                        />
                        <TextField
                          label="Datum poslednje izmene"
                          name="adTimeEdit"
                          type="date"
                          value={formatDate(data.adTimeEdit)}
                          onChange={handleInputChange}
                          fullWidth
                          size="small"
                          className="mb-4"
                          disabled
                        />
                        <TextField
                          label="Izmenio korisnik"
                          name="edit_acUsername"
                          value={data.edit_acUsername}
                          onChange={handleInputChange}
                          fullWidth
                          size="small"
                          disabled
                          className="mb-4"
                        />
                      </div>
                    </div>
                    <div className="float-end">
                      <Button type="submit" variant="contained" color="success">
                        Izmeni stranicu
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default SeoPagesForm;
